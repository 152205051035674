<template>
  <div class="realEstatePanel">
    <div class="mt-2" v-if="loaded">
      <validation-observer ref="refFormObserver">
        <!-- Header: Personal Info -->

        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">
          <b-col cols="12" class="col-12">
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <div class="d-flex">
                    <feather-icon icon="PackageIcon" size="19"/>
                    <h4 class="mb-4 ml-50">
                      {{ msg('Contract').toUpperCase() }} {{ msg('Name').toUpperCase() }}
                    </h4>
                  </div>
                  <b-row>
                    <b-col cols="12" md="3" lg="3">
                      <b-form-group :label="msg('Name')" label-for="realEstate-name">
                        <validation-provider #default="{ errors }" :name="msg('Name')" rules="required">
                          <b-form-input id="realEstate-name" v-model="defaultContractTemplate.name"
                                        :state="errors.length > 0 ? false:null"/>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3" lg="3">
                      <b-form-group :label="msg('Language')" label-for="realEstate-name">
                        <validation-provider #default="{ errors }" :name="msg('Language')" rules="required">
                          <v-select
                              :dir="isRTL ? 'rtl' : 'ltr'"
                              :clearable="false"
                              v-model="defaultContractTemplate.lang"
                              :options="[
            { id: 'EN', name: 'English' },
            { id: 'ES', name: 'Spanish' },
            { id: 'FR', name: 'French' },
            { id: 'DE', name: 'German' },
            { id: 'IT', name: 'Italian' },
            { id: 'PT', name: 'Portuguese' },
            { id: 'RU', name: 'Russian' },
            { id: 'CN', name: 'Chinese' },
            { id: 'JP', name: 'Japanese' }
          ]"
                              class="w-100"
                              label="name"
                              :reduce="(branch) => branch.id"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Branch -->
                    <b-col cols="12" md="3" lg="3">
                      <b-form-group :label="msg('Branch')" label-for="product-branch">
                        <validation-provider #default="{ errors }" :name="msg('Branch')" rules="required">
                          <v-select

                              :dir="isRTL ? 'rtl' : 'ltr'"
                              v-model="defaultContractTemplate.buid"
                              :options="allBranches"
                              class="w-100"
                              label="name"
                              :reduce="(branch) => branch.id"
                              :clearable="false"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>


                    <b-col cols="12" md="3" lg="3">
                      <b-form-group :label="msg('Template type')" label-for="user-docType">
                        <validation-provider #default="{ errors }" :name="msg('Template type')" rules="required">
                          <v-select
                              :dir="isRTL ? 'rtl' : 'ltr'"
                              v-model="defaultContractTemplate.status"
                              :options="[{value: 'BUY_CONTRACT',label:msg('BUY_CONTRACT')},
                          {value: 'RENT_CONTRACT',label:msg('RENT_CONTRACT')},
                          {value: 'BUY_OFFER',label:msg('BUY_OFFER')},
                          {value: 'RENT_OFFER',label:msg('RENT_OFFER')}
                          ]"
                              class="w-100"
                              :reduce="(type) => type.value"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>
                </div>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col cols="12" class="col-12">
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <div class="d-flex">
                    <feather-icon icon="PackageIcon" size="19"/>
                    <h4 class="mb-4 ml-50">
                      {{ msg('Info').toUpperCase() }} </h4>
                  </div>
                  <div>

                    <b-card img-src="https://treasurehome.at/uploads/images/info.gif" img-height="250" img-width="250"
                            img-alt="Card image" img-left class="mb-3">
                      <b-card-text>
                        <p>Bitte nimm den bestehenden Vertrag in deinem aktuellen Format und kopiere den kompletten Text
                          hier in das HTML Textfeld hinein.</p>
                        <p>Alle Daten die zum jeweiligen Feld gehören musst du als „Tag“ von unten in die jeweilige
                          Position ziehen wo diese drinnen zu stehen haben.</p>
                        <p>Z.b. nimmst du den Tag „Vermieter“ und gibst ihn überall dort hinein wo der Name des
                          Vermieters vorkommt.</p>
                        <p>Der Vertrag wird anschließend online generiert und kann dann von beiden Seiten Mieter/Käufer
                          und Eigentümer digital signiert werden. Dafür benötigst du die Handysignatur / ID Austria,
                          solltest du diese nicht haben, dann jetzt anmelden unter:</p>

                        <a target="_blank"
                           href="https://finanzonline.bmf.gv.at/fon">https://finanzonline.bmf.gv.at/fon</a>
                      </b-card-text>
                    </b-card>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>


          <b-col cols="12" class="col-12">
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <div class="d-flex">
                    <feather-icon icon="PackageIcon" size="19"/>
                    <h4 class="mb-4 ml-50">
                      {{ msg('Contract').toUpperCase() }} {{ msg('Template').toUpperCase() }}
                    </h4>
                  </div>
                  <b-col class="mt-3" cols="12" md="12" lg="12">

                    <div class="contract-parts">
                      <div id="contract-part-nav-scroller" class="contract-part-nav-scroller px-3" ref="content">
                        <div v-bind:id="`contract-part-${key}`" class="contract-part"
                             v-for="(parts,key) in partsByTitle"
                             v-bind:key="key">
                          <b-row class="contract-part-paragraph" v-for="partData in parts.data"
                                 v-bind:key="partData.title">
                            <b-col cols="1" class="border-right border-bottom">
                              {{ partData.part.section }}.{{ partData.part.subSection }}
                            </b-col>
                            <b-col class="border-bottom border-right" cols="11">
                              <div v-if="partData.part.editable !== true">
                                <div class="contract-part-actions" v-if="partData.part.type !== 'footer'">
                                  <b-button variant="outline-info" size="sm"
                                            @click="()=>addContractPart(partData.part,partData.index)"><i
                                      class="fas fa-plus"></i>
                                  </b-button>
                                  <b-button variant="outline-warning" size="sm"
                                            @click="()=>editContractPart(partData.index)"><i
                                      class="fas fa-pen"></i></b-button>
                                  <b-button variant="outline-danger" size="sm"
                                            v-if="partData.part.type === 'title' || partData.part.type === 'paragraph'"
                                            @click="()=>onRemoveContractPartRequest(partData.index)"><i
                                      class="fas fa-ban"></i>
                                  </b-button>
                                </div>
                                <div v-html="partData.part.html" v-bind:key="partData.part.position"></div>
                              </div>
                              <div class="py-1" v-if="partData.part.editable === true">
                                <b-row class="pb-1">
                                  <b-col>
                                    <v-select v-bind:value="partData.part.type"
                                              @input="input=>updateTextType(partData.index, input)"
                                              :options="textPartTypes" :clearable="false"
                                              class="w-100" size="sm" label="label"
                                              :reduce="(type) => type.value"
                                              v-bind:disabled="partData.part.type !== 'title' && partData.part.type !== 'paragraph'"
                                    />
                                  </b-col>
                                  <b-col v-if="partData.part.type === 'title'">
                                    <b-form-input v-model="partData.part.text" placeholder="Title"/>
                                  </b-col>
                                  <b-col cols="2">
                                    <b-button class="mx-1" variant="outline-warning"
                                              v-if="partData.part.type === 'title' || partData.part.type === 'paragraph'"
                                              @click="()=>onRemoveContractPartRequest(partData.index)">{{
                                        msg("Remove")
                                      }}
                                    </b-button>
                                    <b-button variant="outline-dark" @click="()=>finalizeEdit(partData.index)">
                                      {{ msg("close") }}
                                    </b-button>
                                  </b-col>
                                </b-row>
                                <div>
                                  <quill-editor
                                      v-model="partData.part.html"
                                      @ready="quill=>onEditorReady(quill)"
                                  ></quill-editor>
                                </div>
                                <div class="text-center">
                                  <b-button size="sm" v-for="tag in textTags" v-bind:key="tag.value"
                                            pill variant="outline-secondary"
                                            @click="()=>insertTag(partData.index, tag.value)">{{ tag.label }}
                                  </b-button>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                      <div class="text-center border-top pt-1">
                        <b-button variant="outline-dark" class="mx-1" style="width: 120px"
                                  @click="cancelAndReturn">{{ msg("Cancel") }}
                        </b-button>
                        <b-button variant="outline-success" style="width: 120px" @click="createContractTemplate">{{
                            msg("Save")
                          }}
                        </b-button>
                      </div>
                    </div>
                  </b-col>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
    <div v-else-if="this.error != null">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="min-height: 300px;text-align: center">
        <div style="margin-top: 20%">
          <b-spinner label="Spinning"/>
          {{ msg('loading') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'contractTemplatePanel',
  components: {},
  data() {
    return {
      loaded: false,
      error: null,
      contractTemplateId: 0,
      defaultContractTemplate: {
        name: '',
      },

      modals: {

        approveContract: {
          active: false,
          signature: null,
          loaded: false,
        },
        partsContract: {
          active: false,
          textParts: {},
          editor: null,
          loaded: false,
        }
      },
      contractTemplateUpdated: false,
      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  props: {
    id: Number,
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    }
  },
  computed: {
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    partsByTitle() {
      console.log("partsByTitle")
      let title = {}
      let currentKey = null;
      let currentPosition = -1;
      let textParts = this.modals.partsContract.textParts;
      console.log("textParts: ", textParts)

      if (textParts == null || !Array.isArray(textParts.parts) || textParts.parts.length === 0) {
        console.log("textParts are null: ", textParts)

        return title;

      }
      textParts.parts.forEach((part, index) => {
        if (part.section - currentPosition >= 1) {
          currentPosition = part.section;
          currentKey = currentPosition;
          title[currentKey] = {data: [], title: `paragraph-${currentKey}`};
        }
        if (part.type === "header" || part.type === "title" || part.type === "footer") {
          title[currentKey].title = part.text;
        }
        title[currentKey].data.push({part, index})
      })
      console.log("title: ", title)

      return title;
    },
    textPartTypes() {
      return [
        {value: "paragraph", label: "Paragraph"},
        {value: "title", label: "Title"}
      ]
    },
    textTags() {
      return [
        {value: "VERMIETERDATEN", label: "VERMIETERDATEN"},
        {value: "MIETERDATEN", label: "MIETERDATEN"},
        {value: "ZIPANDCITY", label: "ZIPANDCITY"},
        {value: "ADDRESSANDHOUSE", label: "ADDRESSANDHOUSE"},
        {value: "APT", label: "APT"},
        {value: "SM", label: "SM"},
        {value: "EXTERNALSM", label: "EXTERNALSM"},
        {value: "STARTDATE", label: "STARTDATE"},
        {value: "ENDDATE", label: "ENDDATE"},
        {value: "IBAN", label: "IBAN"},
        {value: "LANDLORD", label: "LANDLORD"},
        {value: "TOTAL", label: "TOTAL"},
        {value: "BK", label: "BK"},
        {value: "TAX", label: "TAX"},
        {value: "KAUTION", label: "KAUTION"},
        {value: "MIETER", label: "MIETER"},
        {value: "CURRENTDATE", label: "CURRENTDATE"},
        {value: "MIETERVORNAME", label: "MIETERVORNAME"},
        {value: "MIETERNACHNAME", label: "MIETERNACHNAME"},
        {value: "MIETERBERUF", label: "MIETERBERUF"},
        {value: "MIETERBDAY", label: "MIETERBDAY"},
        {value: "MIETERTELEFON", label: "MIETERTELEFON"},
        {value: "MIETERANSCHRIFT", label: "MIETERANSCHRIFT"},
        {value: "MIETEREMAIL", label: "MIETEREMAIL"},
        {value: "DEPOSIT", label: "DEPOSIT"},
        {value: "NUMBEROFPEOPLE", label: "NUMBER_OF_PEOPLE"},
      ]
    },
  },
  created() {
    if (this.$route.params.id == null) {
      return this.error = 'Invalid contractTemplate buid'
    }
    this.buid = this.$route.params.id;
    this.onLoad()
    this.$root.$on('crm::submitContractTemplate', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitContractTemplate', this.listeners.submit)
  },
  methods: {
    ...mapActions('contractTemplate', {
      _register: 'addContractTemplate',
    }),
    ...mapActions('contractTemplate', {
      _getDefaultContractTemplate: 'getDefaultContractTemplate',
    }),
    onLoad() {
      this.loaded = false
      this.loadDefaultContractTemplate()

    },
    createContractTemplate() {
      let $this = this
      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        $this._register(this.defaultContractTemplate)
            .then(
                (id) => {

                  $this.contractTemplateId = id
                  $this.$emit('submitted', {
                    status: true,
                    server: id,
                    id

                  })
                },
                (a) => $this.$emit('submitted', {
                  status: false,
                  server: a
                })
            )
            .finally(() => {
              this.$router.push('/real_estate/contracts/templates');

            })
      })
    },
    addContractPart(part, index) {
      let newPart = {
        text: "", editable: true, html: "", params: [],
        section: part.section, subSection: part.subSection + 1, type: "paragraph"
      }
      for (let i = index + 1; i < this.modals.partsContract.textParts.parts.length; i++) {
        let contractPart = this.modals.partsContract.textParts.parts[i];
        if (contractPart.section !== part.section) {
          break;
        }
        contractPart.subSection += 1;
      }
      this.modals.partsContract.textParts.parts.splice(index + 1, 0, newPart)
    },
    editContractPart(index) {
      this.modals.partsContract.textParts.parts.forEach(part => part.editable = false);
      this.modals.partsContract.textParts.parts[index].editable = true;
    },
    onEditorReady(quill) {
      this.modals.partsContract.editor = quill;
    },
    insertTag(index, value) {
      const selection = this.modals.partsContract.editor.getSelection(); // get position of cursor (index of selection)
      this.modals.partsContract.editor.insertText(selection.index, `#${value}#`);
      let part = this.modals.partsContract.textParts.parts[index];
      if (part.params == null) {
        part.params = [];
      }
      if (part.params.indexOf(value) < 0) {
        part.params.push(value);
      }
    },
    updateTextType(index, type) {
      let part = this.modals.partsContract.textParts.parts[index];
      let currentType = part.type;
      part.type = type;
      if (currentType === type) {
        return;
      }
      if (currentType === 'title') {
        this.substractSection(part, index, 0)
      } else { // convert to title
        let offset = part.subSection;
        for (let i = index; i < this.modals.partsContract.textParts.parts.length; i++) {
          let contractPart = this.modals.partsContract.textParts.parts[i];
          contractPart.section += 1
          if (contractPart.section === part.section) {
            contractPart.subSection -= offset;
          }
        }
      }
    },
    onRemoveContractPartRequest(index) {
      let $this = this;
      this.$bvModal.msgBoxConfirm('Are you sure you want remove it ?', {
        title: 'Please Confirm',
        size: 'sm', buttonSize: 'sm', okVariant: 'danger', okTitle: 'YES', cancelTitle: 'NO', footerClass: 'p-0',
        hideHeaderClose: false, centered: true
      }).then(value => {
        if (value === true) {
          $this.removeContractPart(index);
        }
      })
    },
    removeContractPart(index) {
      console.log("removeContractPart")
      let part = this.modals.partsContract.textParts.parts[index];
      if (part.type === 'title') {
        this.substractSection(part, index, 1)
      } else {
        for (let i = index + 1; i < this.modals.partsContract.textParts.parts.length; i++) {
          let contractPart = this.modals.partsContract.textParts.parts[i];
          if (contractPart.section !== part.section) {
            break;
          }
          contractPart.subSection -= 1;
        }
      }
      this.modals.partsContract.textParts.parts.splice(index, 1);
    },
    substractSection(part, index, indexOffset) {
      let subSectionOffset = Math.max(...this.modals.partsContract.textParts.parts
          .filter(p => p.section === part.section - 1)
          .map(p => p.subSection)) + 1
      for (let i = index + indexOffset; i < this.modals.partsContract.textParts.parts.length; i++) {
        let contractPart = this.modals.partsContract.textParts.parts[i];
        if (contractPart.section === part.section) {
          contractPart.subSection = subSectionOffset++;
        }
        contractPart.section = contractPart.section - 1;
      }
    },
    finalizeEdit(index) {
      this.modals.partsContract.textParts.parts[index].editable = false;
    },
    scrollIntoView(event) {
      event.preventDefault()
      const href = event.target.getAttribute('href')
      const el = href ? document.querySelector(href) : null
      if (el) {
        this.$refs.content.scrollTop = el.offsetTop
      }
    },


    loadDefaultContractTemplate() {
      let $this = this
      $this.loaded = false
      console.log("loadDefaultContractTemplate ")

      this._getDefaultContractTemplate($this.buid)
          .then((ans) => {

            $this.defaultContractTemplate = ans
            $this.defaultContractTemplate.name = null;
            $this.defaultContractTemplate.id = null;

            let textParts = (ans || {});

            if (Array.isArray(textParts.parts)) {
              textParts.parts = textParts.parts.map(part => ({...part, editable: false}))
            }
            $this.modals.partsContract.textParts = textParts;

            //Set page as loaded
            $this.loaded = true
            $this.contractTemplateUpdated = false;
          }, (error) => {
            $this.error = error
          })
    }
    ,

    cancelAndReturn() {

      this.$router.push('/real_estate/contracts/templates');


    },
    onContractRequestFailed(errorData) {
      let $this = this;
      console.log("onContractRequestFailed", {error: errorData})
      let error = {errorType: "UNKNOWN", message: "Unknown error"}
      try {
        error = JSON.parse(new TextDecoder("utf-8").decode(errorData.data))
      } catch (e) {
        console.log(e)
      }
      let message = error.objects != null && error.objects.field != null ? error.objects.field + " is required" : error.message;
      this.$bvModal.msgBoxOk(message, {
        title: 'Error requesting contract',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      }).then(value => {
        $this.modals.approveContract.active = false;
      })

      console.log("onContractRequestFailed", error)
    },

    validate(field) {
      return this.$v.defaultContractTemplate[field] != null && this.$v.defaultContractTemplate[field].$error === true
    }
    ,


  },

}
</script>


